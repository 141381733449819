import GammaVegaLogo from "./static/img/gammaVegaLogo.png"
import BanxLogo from "./static/img/banx-logo.png"
import OHLogo from "./static/img/OHLogo.png"

export const PRIMARY = 'rgba(86, 28, 50, 1)' //'rgba(0, 119, 200, 1)'//'rgba(0, 51, 102, 1)'
export const PRIMARY_BACKGROUND = 'rgba(86, 28, 50, 0.1)' //'rgba(0, 119, 200, 0.1)'//'rgba(0, 51, 102, 0.1)'
export const PRIMARY_BACKGROUND_DARK = 'rgba(86, 28, 50, 0.3)'
export const PRIMARY_TRANSPARENT = 'rgba(86, 28, 50, 0.0)'

export const SECONDARY = 'rgba(143, 80, 99, 1)' //'rgba(66, 66, 66, 1)'//'rgba(124, 193, 65, 1)'
export const SECONDARY_BACKGROUND = 'rgba(143, 80, 99, 0.1)' //'rgba(66, 66, 66, 0.1)'//'rgba(124, 193, 65, 0.1)'
export const SECONDARY_BACKGROUND_DARK = 'rgba(143, 80, 99, 0.3)'//'rgba(124, 193, 65, 0.1)'



export const COMPANY_LOGO = BanxLogo
export const OH_LOGO = OHLogo
