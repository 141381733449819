import React, { Fragment } from "react"
import { Grid, Typography, InputAdornment, Divider, FormControlLabel, Button } from "@material-ui/core"
import CustomTextField from "../componets/CustomTextField";
import CustomSwitch from "../componets/CustomSwitch";
import OptionCalculator from "./OptionCalculator";
import moment from "moment"
import { setOptionInformation, setGreeks, setCallMatrix, setPutMatrix, setCalculateMatrix } from "../store/actions/optionDataActions";
import { connect } from "react-redux";
import { PRIMARY, SECONDARY } from "../config";
import { withSnackbar } from "notistack"

class DataForm extends React.Component {

    constructor() {
        super()
        this.state = {
            underlyingPrice: '',
            exercisePrice: '',
            volatility: '',
            interestRate: '',
            callPriceIV: '',
            putPriceIV: '',
            dividend: '',
            priceInterval: '',
            volatilityInterval: '',
            purchaseDate: moment().format('YYYY-MM-DD'),
            lastTradingDay: moment().format('YYYY-MM-DD'),
            dividendDate: moment().format('YYYY-MM-DD'),
            dividendChecked: false,
            matrixChecked: false,
        }
        this.optionCalculator = new OptionCalculator()
    }
    
    calculate = event => {

        if (this.state.exercisePrice === '') {
            this.props.enqueueSnackbar("Exercise Price needs to be set in order to calculate!", {variant: 'warning'})
            return null
        }

        if (this.state.underlyingPrice === '') {
            this.props.enqueueSnackbar("Underlying Price needs to be set in order to calculate!", {variant: 'warning'})
            return null
        }

        if (this.state.volatility === '') {
            this.props.enqueueSnackbar("Volatility needs to be set in order to calculate!", {variant: 'warning'})
            return null
        }

        if (this.state.interestRate === '') {
            this.props.enqueueSnackbar("Interest Rate needs to be set in order to calculate!", {variant: 'warning'})
            return null
        }


        if (this.state.matrixChecked) {
            if (this.state.priceInterval === '' || this.state.volatilityInterval === '') {
                this.props.enqueueSnackbar("Price Interval and Volatility Interval need to be set in order to calculate Price/Volatility Matrix", {variant: 'warning'})
                return null
            }
        }

        if (this.state.dividendChecked) {
            if (this.state.dividend === '') {
                this.props.enqueueSnackbar("Dividend needs to be set in order to calculate!", {variant: 'warning'})
                return null
            }
        }

        const purchaseDate = moment(this.state.purchaseDate)
        const lastTradingDay = moment(this.state.lastTradingDay)

        const days = lastTradingDay.diff(purchaseDate, 'days') + 1
        const underlyingPrice = parseFloat(this.state.underlyingPrice)
        const strikePrice = parseFloat(this.state.exercisePrice)
        const volatility = parseFloat(this.state.volatility) / 100
        const interestRate = parseFloat(this.state.interestRate) / 100

        var daysDividend = 0
        var dividend = 0

        if (this.state.dividendChecked) {
            const dividendDate = moment(this.state.dividendDate)
            daysDividend = lastTradingDay.diff(dividendDate, 'days') + 1
            dividend = parseFloat(this.state.dividend)

            if (daysDividend < 0 ) {
                daysDividend = 0
                dividend = 0
            }

        }

        const callPriceIV = parseFloat(this.state.callPriceIV)
        const putPriceIV = parseFloat(this.state.putPriceIV)

        const callPrice = this.optionCalculator.blackScholesCall(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend)
        const putPrice = this.optionCalculator.blackScholesPut(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend)
        const callImpliedVolatility = this.optionCalculator.impliedVolatilityCall(underlyingPrice, strikePrice, days, interestRate, dividend, callPriceIV, daysDividend)
        const putImpliedVolatility = this.optionCalculator.impliedVolatilityPut(underlyingPrice, strikePrice, days, interestRate, dividend, putPriceIV, daysDividend)
        
        const optionInformation = {
            days: days,
            underlyingPrice: underlyingPrice,
            strikePrice: strikePrice,
            volatility: volatility,
            interestRate: interestRate,
            callPrice: callPrice,
            putPrice: putPrice,
            daysDividend: daysDividend,
            dividend: dividend,
            callImpliedVolatility: callImpliedVolatility,
            putImpliedVolatility: putImpliedVolatility
        }



        this.props.setOptionInformation(optionInformation)

        const greeks = {
            call: {
                delta: this.optionCalculator.deltaCall(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                theta: this.optionCalculator.thetaCall(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                gamma: this.optionCalculator.gammaCall(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                vega: this.optionCalculator.vegaCall(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                rho: this.optionCalculator.rhoCall(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                vanna: this.optionCalculator.vannaCall(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                charm: this.optionCalculator.charmCall(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                vomma: this.optionCalculator.volgaCall(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                veta: this.optionCalculator.vegaDecayCall(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                speed: this.optionCalculator.speedCall(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                zomma: this.optionCalculator.zommaCall(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                color: this.optionCalculator.colorCall(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend)
            },
            put: {
                delta: this.optionCalculator.deltaPut(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                theta: this.optionCalculator.thetaPut(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                gamma: this.optionCalculator.gammaPut(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                vega: this.optionCalculator.vegaPut(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                rho: this.optionCalculator.rhoPut(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                vanna: this.optionCalculator.vannaPut(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                charm: this.optionCalculator.charmPut(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                vomma: this.optionCalculator.volgaPut(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                veta: this.optionCalculator.vegaDecayPut(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                speed: this.optionCalculator.speedPut(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                zomma: this.optionCalculator.zommaPut(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend),
                color: this.optionCalculator.colorPut(underlyingPrice, strikePrice, days, interestRate, dividend, volatility, daysDividend)
            }
        }

        this.props.setGreeks(greeks)

        if (this.state.matrixChecked) {

            const priceInterval = parseFloat(this.state.priceInterval)
            const volatilityInterval =  parseFloat(this.state.volatilityInterval)/100

            var prices = []
            var volatilities = []
            var callMatrix = []
            var putMatrix = []

            for (var i = -4; i < 5; i++) {
                prices.push(underlyingPrice + i * priceInterval)
                volatilities.push(volatility + i * volatilityInterval)
            }

            for (var p in prices) {
                for (var v in volatilities) {
                    callMatrix.push([prices[p], volatilities[v], this.optionCalculator.blackScholesCall(prices[p], strikePrice, days, interestRate, dividend, volatilities[v], daysDividend)])
                    putMatrix.push([prices[p], volatilities[v], this.optionCalculator.blackScholesPut(prices[p], strikePrice, days, interestRate, dividend, volatilities[v], daysDividend)])
                }
            }
            this.props.setCallMatrix(callMatrix)
            this.props.setPutMatrix(putMatrix)
        }
        

    }

    handelSwitchChanged = name => event => {
        this.setState({ [name]: event.target.checked })

        if (name === 'matrixChecked') {
            this.props.setCalculateMatrix(event.target.checked)
        }
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ snackbarOpen: false})
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }
    render() {
        return (
            <form >
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant='h6' style={{ color: PRIMARY, position: 'relative', top: '50%', WebkitTransform: 'translateY(-50%)', transform: 'translatey(-50%)' }}>General Data</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <CustomTextField
                            id='purchase_date'
                            label='Purchase Date'
                            type='date'
                            value={this.state.purchaseDate}
                            onChange={this.handleChange('purchaseDate')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <CustomTextField
                            id='last_trading_day'
                            label='Last Trading Day'
                            type='date'
                            value={this.state.lastTradingDay}
                            onChange={this.handleChange('lastTradingDay')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <CustomTextField
                            id="exercise-price"
                            label="Exercise Price"
                            type="number"
                            style={{ minWidth: '100%' }}
                            value={this.state.exercisePrice}
                            onChange={this.handleChange('exercisePrice')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <CustomTextField
                            id="underlying-price"
                            label="Underlying Price"
                            type="number"
                            value={this.state.underlyingPrice}
                            onChange={this.handleChange('underlyingPrice')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <CustomTextField
                            id="vaolatility"
                            label="Volatility"
                            type="number"
                            style={{ minWidth: '100%' }}
                            value={this.state.volatility}
                            onChange={this.handleChange('volatility')}
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <CustomTextField
                            id="interest-rate"
                            label="Interest Rate"
                            margin='dense'
                            type="number"
                            value={this.state.interestRate}
                            onChange={this.handleChange('interestRate')}
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    checked={this.state.dividendChecked}
                                    onChange={this.handelSwitchChanged('dividendChecked')}
                                    value='dividendChecked'
                                />
                            }
                            label="Dividend Paying"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    checked={this.state.matrixChecked}
                                    onChange={this.handelSwitchChanged('matrixChecked')}
                                    value='matrixChecked'
                                />
                            }
                            label="Calculate Price/Volatility Matrix"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider variant="middle" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant='h6' style={{ color: PRIMARY, position: 'relative', top: '50%', WebkitTransform: 'translateY(-50%)', transform: 'translatey(-50%)' }}>Prices for Implied Volatility</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <CustomTextField
                            id="call-iv"
                            variant='outlined'
                            label="Call"
                            margin='dense'
                            type="number"
                            style={{ minWidth: '100%' }}
                            value={this.state.callPriceIV}
                            onChange={this.handleChange('callPriceIV')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <CustomTextField
                            id="put-iv"
                            variant='outlined'
                            label="Put"
                            margin='dense'
                            type="number"
                            style={{ minWidth: '100%' }}
                            value={this.state.putPriceIV}
                            onChange={this.handleChange('putPriceIV')}
                        />
                    </Grid>

                    {this.state.dividendChecked ? (
                        <Fragment>
                            <Grid item xs={12}>
                                <Divider variant="middle" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant='h6' style={{ color: PRIMARY, position: 'relative', top: '50%', WebkitTransform: 'translateY(-50%)', transform: 'translatey(-50%)' }}>Dividend</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <CustomTextField
                                    id='dividend-date'
                                    variant='outlined'
                                    label='Dividend Date'
                                    type='date'
                                    margin='dense'
                                    value={this.state.dividendDate}
                                    onChange={this.handleChange('dividendDate')}
                                    style={{ minWidth: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <CustomTextField
                                    id="dividend"
                                    variant='outlined'
                                    label="Dividend"
                                    margin='dense'
                                    type="number"
                                    style={{ minWidth: '100%' }}
                                    value={this.state.dividend}
                                    onChange={this.handleChange('dividend')}
                                />
                            </Grid>
                        </Fragment>
                    ) : (null)}
                    {this.state.matrixChecked ? (
                        <Fragment>
                            <Grid item xs={12}>
                                <Divider variant="middle" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant='h6' style={{ color: PRIMARY, position: 'relative', top: '50%', WebkitTransform: 'translateY(-50%)', transform: 'translatey(-50%)' }}>Price/Volatility Matrix</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <CustomTextField
                                    id="price-interval"
                                    variant='outlined'
                                    label="Price Interval"
                                    margin='dense'
                                    type="number"
                                    style={{ minWidth: '100%' }}
                                    value={this.state.priceInterval}
                                    onChange={this.handleChange('priceInterval')}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <CustomTextField
                                    id="volatility-interval"
                                    variant='outlined'
                                    label="Volatility Interval"
                                    margin='dense'
                                    type="number"
                                    style={{ minWidth: '100%' }}
                                    value={this.state.volatilityInterval}
                                    onChange={this.handleChange('volatilityInterval')}
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                />
                            </Grid>
                        </Fragment>
                    ) : (null)}
                    <Grid item xs={12} >
                    <div style={{ width:'100%' }}>
                        <Button 
                            variant='contained' 
                            onClick={this.calculate}
                            style={{ backgroundColor: '#0d5398', color:'#ffffff', float:'right' }}
                        >
                            Calculate
                        </Button>
                    </div>
                        
                    </Grid>

                </Grid>
                {/* <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    open={this.state.snackbarOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackbarClose}
                >
                    <MySnackbarContent
                        onClose={this.handleSnackbarClose}
                        variant="error"
                        message="Price Interval and Volatility Interval need to be set in order to calculate Price/Volatility Matrix"
                    />
                </Snackbar> */}
            </form>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOptionInformation: (optionInformation) => dispatch(setOptionInformation(optionInformation)),
        setGreeks: (greeks) => dispatch(setGreeks(greeks)),
        setCallMatrix: (matrix) => dispatch(setCallMatrix(matrix)),
        setPutMatrix: (matrix) => dispatch(setPutMatrix(matrix)),
        setCalculateMatrix: (calculate) => dispatch(setCalculateMatrix(calculate))
    }
}

export default connect(null, mapDispatchToProps)(withSnackbar(DataForm))